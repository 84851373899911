<script>
export default {
  name: "App_2",
  mounted() {
    setInterval(() => {
      // eslint-disable-next-line no-debugger
      debugger;
    }, 1000)
  }
}
</script>

<template>
  <router-view/>
</template>

<style scoped lang="less">
</style>